<template>
  <div>
    <b-card class="mb-4">
      <b-card-text>
        <h5 class="card-title">Solid Icons</h5>
        <b-row class="icons">
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-address-book"></i> fas
            fa-address-book
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-address-card"></i> fas
            fa-address-card
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-adjust"></i> fas fa-adjust
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-align-center"></i> fas
            fa-align-center
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-align-justify"></i> fas
            fa-align-justify
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-align-left"></i> fas
            fa-align-left
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-align-right"></i> fas
            fa-align-right
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-allergies"></i> fas fa-allergies
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-ambulance"></i> fas fa-ambulance
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i
              class="mr-2 text-truncate fas fa-american-sign-language-interpreting"
            ></i>
            fas fa-american-sign-language-interpreting
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-anchor"></i> fas fa-anchor
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-angle-double-down"></i> fas
            fa-angle-double-down
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-angle-double-left"></i> fas
            fa-angle-double-left
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-angle-double-right"></i> fas
            fa-angle-double-right
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-angle-double-up"></i> fas
            fa-angle-double-up
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-angle-down"></i> fas
            fa-angle-down
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-angle-left"></i> fas
            fa-angle-left
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-angle-right"></i> fas
            fa-angle-right
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-angle-up"></i> fas fa-angle-up
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-archive"></i> fas fa-archive
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-arrow-alt-circle-down"></i> fas
            fa-arrow-alt-circle-down
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-arrow-alt-circle-left"></i> fas
            fa-arrow-alt-circle-left
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-arrow-alt-circle-right"></i> fas
            fa-arrow-alt-circle-right
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-arrow-alt-circle-up"></i> fas
            fa-arrow-alt-circle-up
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-arrow-circle-down"></i> fas
            fa-arrow-circle-down
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-arrow-circle-left"></i> fas
            fa-arrow-circle-left
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-arrow-circle-right"></i> fas
            fa-arrow-circle-right
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-arrow-circle-up"></i> fas
            fa-arrow-circle-up
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-arrow-down"></i> fas
            fa-arrow-down
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-arrow-left"></i> fas
            fa-arrow-left
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-arrow-right"></i> fas
            fa-arrow-right
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-arrow-up"></i> fas fa-arrow-up
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-arrows-alt"></i> fas
            fa-arrows-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-arrows-alt-h"></i> fas
            fa-arrows-alt-h
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-arrows-alt-v"></i> fas
            fa-arrows-alt-v
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i
              class="mr-2 text-truncate fas fa-assistive-listening-systems"
            ></i>
            fas fa-assistive-listening-systems
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-asterisk"></i> fas fa-asterisk
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-at"></i> fas fa-at
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-audio-description"></i> fas
            fa-audio-tion
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-backward"></i> fas fa-backward
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-balance-scale"></i> fas
            fa-balance-scale
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-ban"></i> fas fa-ban
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-band-aid"></i> fas fa-band-aid
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-barcode"></i> fas fa-barcode
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-bars"></i> fas fa-bars
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-baseball-ball"></i> fas
            fa-baseball-ball
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-basketball-ball"></i> fas
            fa-basketba
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-bath"></i> fas fa-bath
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-battery-empty"></i> fas
            fa-battery-empty
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-battery-full"></i> fas
            fa-battery-full
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-battery-half"></i> fas
            fa-battery-half
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-battery-quarter"></i> fas
            fa-battery-quarter
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-battery-three-quarters"></i> fas
            fa-battery-three-quarters
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-bed"></i> fas fa-bed
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-beer"></i> fas fa-beer
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-bell"></i> fas fa-bell
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-bell-slash"></i> fas
            fa-bell-slash
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-bicycle"></i> fas fa-bicycle
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-binoculars"></i> fas
            fa-binoculars
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-birthday-cake"></i> fas
            fa-birthday-cake
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-blind"></i> fas fa-blind
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-bold"></i> fas fa-bold
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-bolt"></i> fas fa-bolt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-bomb"></i> fas fa-bomb
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-book"></i> fas fa-book
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-bookmark"></i> fas fa-bookmark
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-bowling-ball"></i> fas
            fa-bowling-ball
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-box"></i> fas fa-box
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-box-open"></i> fas fa-box-open
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-boxes"></i> fas fa-boxes
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-braille"></i> fas fa-braille
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-briefcase"></i> fas fa-briefcase
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-briefcase-medical"></i> fas
            fa-briefcical
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-bug"></i> fas fa-bug
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-building"></i> fas fa-building
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-bullhorn"></i> fas fa-bullhorn
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-bullseye"></i> fas fa-bullseye
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-burn"></i> fas fa-burn
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-bus"></i> fas fa-bus
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-calculator"></i> fas
            fa-calculator
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-calendar"></i> fas fa-calendar
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-calendar-alt"></i> fas
            fa-calendar-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-calendar-check"></i> fas
            fa-calendar-check
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-calendar-minus"></i> fas
            fa-calendar-minus
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-calendar-plus"></i> fas
            fa-calendar-plus
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-calendar-times"></i> fas
            fa-calendar-times
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-camera"></i> fas fa-camera
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-camera-retro"></i> fas
            fa-camera-retro
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-capsules"></i> fas fa-capsules
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-car"></i> fas fa-car
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-caret-down"></i> fas
            fa-caret-down
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-caret-left"></i> fas
            fa-caret-left
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-caret-right"></i> fas
            fa-caret-right
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-caret-square-down"></i> fas
            fa-caret-down
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-caret-square-left"></i> fas
            fa-caret-left
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-caret-square-right"></i> fas
            fa-caret-right
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-caret-square-up"></i> fas
            fa-caret-sq
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-caret-up"></i> fas fa-caret-up
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-cart-arrow-down"></i> fas
            fa-cart-arr
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-cart-plus"></i> fas fa-cart-plus
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-certificate"></i> fas
            fa-certificate
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chart-area"></i> fas
            fa-chart-area
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chart-bar"></i> fas fa-chart-bar
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chart-line"></i> fas
            fa-chart-line
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chart-pie"></i> fas fa-chart-pie
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-check"></i> fas fa-check
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-check-circle"></i> fas
            fa-check-circle
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-check-square"></i> fas
            fa-check-square
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chess"></i> fas fa-chess
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chess-bishop"></i> fas
            fa-chess-bishop
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chess-board"></i> fas
            fa-chess-board
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chess-king"></i> fas
            fa-chess-king
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chess-knight"></i> fas
            fa-chess-knight
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chess-pawn"></i> fas
            fa-chess-pawn
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chess-queen"></i> fas
            fa-chess-queen
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chess-rook"></i> fas
            fa-chess-rook
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chevron-circle-down"></i> fas
            fa-chevron-circle-down
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chevron-circle-left"></i> fas
            fa-chevron-circle-left
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chevron-circle-right"></i> fas
            fa-chevron-circle-right
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chevron-circle-up"></i> fas
            fa-chevroe-up
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chevron-down"></i> fas
            fa-chevron-down
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chevron-left"></i> fas
            fa-chevron-left
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chevron-right"></i> fas
            fa-chevron-right
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-chevron-up"></i> fas
            fa-chevron-up
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-child"></i> fas fa-child
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-circle"></i> fas fa-circle
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-circle-notch"></i> fas
            fa-circle-notch
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-clipboard"></i> fas fa-clipboard
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-clipboard-check"></i> fas
            fa-clipboard-check
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-clipboard-list"></i> fas
            fa-clipboard-list
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-clock"></i> fas fa-clock
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-clone"></i> fas fa-clone
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-closed-captioning"></i> fas
            fa-closedning
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-cloud"></i> fas fa-cloud
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-cloud-download-alt"></i> fas
            fa-cloudad-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-cloud-upload-alt"></i> fas
            fa-cloud-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-code"></i> fas fa-code
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-code-branch"></i> fas
            fa-code-branch
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-coffee"></i> fas fa-coffee
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-cog"></i> fas fa-cog
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-cogs"></i> fas fa-cogs
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-columns"></i> fas fa-columns
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-comment"></i> fas fa-comment
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-comment-alt"></i> fas
            fa-comment-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-comment-dots"></i> fas
            fa-comment-dots
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-comment-slash"></i> fas
            fa-comment-slash
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-comments"></i> fas fa-comments
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-compass"></i> fas fa-compass
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-compress"></i> fas fa-compress
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-copy"></i> fas fa-copy
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-copyright"></i> fas fa-copyright
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-couch"></i> fas fa-couch
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-credit-card"></i> fas
            fa-credit-card
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-crop"></i> fas fa-crop
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-crosshairs"></i> fas
            fa-crosshairs
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-cube"></i> fas fa-cube
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-cubes"></i> fas fa-cubes
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-cut"></i> fas fa-cut
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-database"></i> fas fa-database
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-deaf"></i> fas fa-deaf
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-desktop"></i> fas fa-desktop
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-diagnoses"></i> fas fa-diagnoses
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-dna"></i> fas fa-dna
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-dollar-sign"></i> fas
            fa-dollar-sign
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-dolly"></i> fas fa-dolly
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-dolly-flatbed"></i> fas
            fa-dolly-flatbed
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-donate"></i> fas fa-donate
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-dot-circle"></i> fas
            fa-dot-circle
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-dove"></i> fas fa-dove
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-download"></i> fas fa-download
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-edit"></i> fas fa-edit
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-eject"></i> fas fa-eject
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-ellipsis-h"></i> fas
            fa-ellipsis-h
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-ellipsis-v"></i> fas
            fa-ellipsis-v
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-envelope"></i> fas fa-envelope
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-envelope-open"></i> fas
            fa-envelope-open
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-envelope-square"></i> fas
            fa-envelope-square
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-eraser"></i> fas fa-eraser
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-euro-sign"></i> fas fa-euro-sign
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-exchange-alt"></i> fas
            fa-exchange-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-exclamation"></i> fas
            fa-exclamation
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-exclamation-circle"></i> fas
            fa-exclamation-circle
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-exclamation-triangle"></i> fas
            fa-exclamation-triangle
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-expand"></i> fas fa-expand
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-expand-arrows-alt"></i> fas
            fa-expand-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-external-link-alt"></i> fas
            fa-external-link-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-external-link-square-alt"></i>
            fas fa-external-link-square-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-eye"></i> fas fa-eye
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-eye-dropper"></i> fas
            fa-eye-dropper
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-eye-slash"></i> fas fa-eye-slash
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-fast-backward"></i> fas
            fa-fast-backward
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-fast-forward"></i> fas
            fa-fast-forward
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-fax"></i> fas fa-fax
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-female"></i> fas fa-female
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-fighter-jet"></i> fas
            fa-fighter-jet
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-file"></i> fas fa-file
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-file-alt"></i> fas fa-file-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-file-archive"></i> fas
            fa-file-archive
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-file-audio"></i> fas
            fa-file-audio
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-file-code"></i> fas fa-file-code
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-file-excel"></i> fas
            fa-file-excel
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-file-image"></i> fas
            fa-file-image
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-file-medical"></i> fas
            fa-file-medical
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-file-medical-alt"></i> fas
            fa-file-medical-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-file-pdf"></i> fas fa-file-pdf
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-file-powerpoint"></i> fas
            fa-file-pow
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-file-video"></i> fas
            fa-file-video
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-file-word"></i> fas fa-file-word
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-film"></i> fas fa-film
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-filter"></i> fas fa-filter
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-fire"></i> fas fa-fire
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-fire-extinguisher"></i> fas
            fa-fire-extinguisher
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-first-aid"></i> fas fa-first-aid
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-flag"></i> fas fa-flag
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-flag-checkered"></i> fas
            fa-flag-checkered
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-flask"></i> fas fa-flask
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-folder"></i> fas fa-folder
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-folder-open"></i> fas
            fa-folder-open
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-font"></i> fas fa-font
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-football-ball"></i> fas
            fa-football-ball
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-forward"></i> fas fa-forward
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-frown"></i> fas fa-frown
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-futbol"></i> fas fa-futbol
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-gamepad"></i> fas fa-gamepad
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-gavel"></i> fas fa-gavel
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-gem"></i> fas fa-gem
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-genderless"></i> fas
            fa-genderless
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-gift"></i> fas fa-gift
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-glass-martini"></i> fas
            fa-glass-martini
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-globe"></i> fas fa-globe
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-golf-ball"></i> fas fa-golf-ball
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-graduation-cap"></i> fas
            fa-graduation-cap
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-h-square"></i> fas fa-h-square
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hand-holding"></i> fas
            fa-hand-holding
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hand-holding-heart"></i> fas
            fa-hand-holding-heart
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hand-holding-usd"></i> fas
            fa-hand-holding-usd
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hand-lizard"></i> fas
            fa-hand-lizard
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hand-paper"></i> fas
            fa-hand-paper
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hand-peace"></i> fas
            fa-hand-peace
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hand-point-down"></i> fas
            fa-hand-point-down
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hand-point-left"></i> fas
            fa-hand-point-left
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hand-point-right"></i> fas
            fa-hand-point-right
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hand-point-up"></i> fas
            fa-hand-point-up
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hand-pointer"></i> fas
            fa-hand-pointer
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hand-rock"></i> fas fa-hand-rock
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hand-scissors"></i> fas
            fa-hand-scissors
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hand-spock"></i> fas
            fa-hand-spock
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hands"></i> fas fa-hands
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hands-helping"></i> fas
            fa-hands-helping
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-handshake"></i> fas fa-handshake
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hashtag"></i> fas fa-hashtag
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hdd"></i> fas fa-hdd
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-heading"></i> fas fa-heading
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-headphones"></i> fas
            fa-headphones
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-heart"></i> fas fa-heart
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-heartbeat"></i> fas fa-heartbeat
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-history"></i> fas fa-history
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hockey-puck"></i> fas
            fa-hockey-puck
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-home"></i> fas fa-home
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hospital"></i> fas fa-hospital
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hospital-alt"></i> fas
            fa-hospital-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hospital-symbol"></i> fas
            fa-hospital
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hourglass"></i> fas fa-hourglass
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hourglass-end"></i> fas
            fa-hourglass-end
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hourglass-half"></i> fas
            fa-hourglass-half
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-hourglass-start"></i> fas
            fa-hourglass-start
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-i-cursor"></i> fas fa-i-cursor
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-id-badge"></i> fas fa-id-badge
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-id-card"></i> fas fa-id-card
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-id-card-alt"></i> fas
            fa-id-card-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-image"></i> fas fa-image
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-images"></i> fas fa-images
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-inbox"></i> fas fa-inbox
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-indent"></i> fas fa-indent
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-industry"></i> fas fa-industry
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-info"></i> fas fa-info
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-info-circle"></i> fas
            fa-info-circle
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-italic"></i> fas fa-italic
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-key"></i> fas fa-key
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-keyboard"></i> fas fa-keyboard
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-language"></i> fas fa-language
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-laptop"></i> fas fa-laptop
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-leaf"></i> fas fa-leaf
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-lemon"></i> fas fa-lemon
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-level-down-alt"></i> fas
            fa-level-down-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-level-up-alt"></i> fas
            fa-level-up-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-life-ring"></i> fas fa-life-ring
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-lightbulb"></i> fas fa-lightbulb
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-link"></i> fas fa-link
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-lira-sign"></i> fas fa-lira-sign
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-list"></i> fas fa-list
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-list-alt"></i> fas fa-list-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-list-ol"></i> fas fa-list-ol
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-list-ul"></i> fas fa-list-ul
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-location-arrow"></i> fas
            fa-location-arrow
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-lock"></i> fas fa-lock
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-lock-open"></i> fas fa-lock-open
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-long-arrow-alt-down"></i> fas
            fa-long-arrow-alt-down
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-long-arrow-alt-left"></i> fas
            fa-long-arrow-alt-left
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-long-arrow-alt-right"></i> fas
            fa-long-arrow-alt-right
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-long-arrow-alt-up"></i> fas
            fa-long-arrow-alt-up
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-low-vision"></i> fas
            fa-low-vision
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-magic"></i> fas fa-magic
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-magnet"></i> fas fa-magnet
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-male"></i> fas fa-male
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-map"></i> fas fa-map
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-map-marker"></i> fas
            fa-map-marker
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-map-marker-alt"></i> fas
            fa-map-marker-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-map-pin"></i> fas fa-map-pin
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-map-signs"></i> fas fa-map-signs
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-mars"></i> fas fa-mars
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-mars-double"></i> fas
            fa-mars-double
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-mars-stroke"></i> fas
            fa-mars-stroke
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-mars-stroke-h"></i> fas
            fa-mars-stroke-h
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-mars-stroke-v"></i> fas
            fa-mars-stroke-v
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-medkit"></i> fas fa-medkit
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-meh"></i> fas fa-meh
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-mercury"></i> fas fa-mercury
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-microchip"></i> fas fa-microchip
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-microphone"></i> fas
            fa-microphone
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-microphone-slash"></i> fas
            fa-microphone-slash
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-minus"></i> fas fa-minus
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-minus-circle"></i> fas
            fa-minus-circle
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-minus-square"></i> fas
            fa-minus-square
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-mobile"></i> fas fa-mobile
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-mobile-alt"></i> fas
            fa-mobile-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-money-bill-alt"></i> fas
            money-bill-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-moon"></i> fas fa-moon
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-motorcycle"></i> fas
            fa-motorcycle
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-mouse-pointer"></i> fas
            fa-mouse-pointer
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-music"></i> fas fa-music
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-neuter"></i> fas fa-neuter
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-newspaper"></i> fas fa-newspaper
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-notes-medical"></i> fas
            fa-notes-medical
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-object-group"></i> fas
            fa-object-group
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-object-ungroup"></i> fas
            fa-object-ungroup
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-outdent"></i> fas fa-outdent
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-paint-brush"></i> fas
            fa-paint-brush
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-pallet"></i> fas fa-pallet
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-paper-plane"></i> fas
            fa-paper-plane
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-paperclip"></i> fas fa-paperclip
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-parachute-box"></i> fas
            fa-parachute-box
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-paragraph"></i> fas fa-paragraph
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-paste"></i> fas fa-paste
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-pause"></i> fas fa-pause
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-pause-circle"></i> fas
            pause-circle
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-paw"></i> fas fa-fa-paw
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-pen-square"></i> fas
            fa-pen-square
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-pencil-alt"></i> fas
            fa-pencil-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-people-carry"></i> fas
            fa-people-carry
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-percent"></i> fas fa-percent
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-phone"></i> fas fa-phone
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-phone-slash"></i> fas
            fa-phone-slash
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-phone-square"></i> fas
            fa-phone-square
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-phone-volume"></i> fas
            fa-phone-volume
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-piggy-bank"></i> fas
            fa-piggy-bank
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-pills"></i> fas fa-pills
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-plane"></i> fas fa-plane
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-play"></i> fas fa-play
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-play-circle"></i> fas
            fa-play-circle
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-plug"></i> fas fa-plug
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-plus"></i> fas fa-plus
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-plus-circle"></i> fas
            fa-plus-circle
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-plus-square"></i> fas
            fa-plus-square
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-podcast"></i> fas fa-podcast
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-poo"></i> fas fa-poo
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-pound-sign"></i> fas
            fa-pound-sign
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-power-off"></i> fas fa-power-off
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-prescription-bottle"></i> fas
            fa-prescription-bottle
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-prescription-bottle-alt"></i>
            fas fa-prescription-bottle-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-print"></i> fas fa-print
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-procedures"></i> fas
            fa-procedures
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-puzzle-piece"></i> fas
            fa-puzzle-piece
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-qrcode"></i> fas fa-qrcode
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-question"></i> fas fa-question
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-question-circle"></i> fas
            fa-question
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-quidditch"></i> fas fa-quidditch
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-quote-left"></i> fas
            fa-quote-left
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-quote-right"></i> fas
            fa-quote-right
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-random"></i> fas fa-random
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-recycle"></i> fas fa-recycle
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-redo"></i> fas fa-redo
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-redo-alt"></i> fas fa-redo-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-registered"></i> fas
            fa-registered
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-reply"></i> fas fa-reply
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-reply-all"></i> fas fa-reply-all
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-retweet"></i> fas fa-retweet
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-ribbon"></i> fas fa-ribbon
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-road"></i> fas fa-road
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-rocket"></i> fas fa-rocket
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-rss"></i> fas fa-rss
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-rss-square"></i> fas
            fa-rss-square
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-ruble-sign"></i> fas
            fa-ruble-sign
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-rupee-sign"></i> fas
            fa-rupee-sign
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-save"></i> fas fa-save
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-search"></i> fas fa-search
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-search-minus"></i> fas
            fa-search-minus
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-search-plus"></i> fas
            fa-search-plus
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-seedling"></i> fas fa-seedling
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-server"></i> fas fa-server
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-share"></i> fas fa-share
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-share-alt"></i> fas fa-share-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-share-alt-square"></i> fas
            fa-share-alt-square
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-share-square"></i> fas
            fa-share-square
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-shekel-sign"></i> fas
            fa-shekel-sign
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-shield-alt"></i> fas
            fa-shield-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-ship"></i> fas fa-ship
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-shipping-fast"></i> fas
            fa-shipping-fast
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-shopping-bag"></i> fas
            fa-shopping-bag
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-shopping-basket"></i> fas
            fa-shopping-basket
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-shopping-cart"></i> fas
            fa-shopping-cart
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-shower"></i> fas fa-shower
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-sign"></i> fas fa-sign
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-sign-in-alt"></i> fas
            fa-sign-in-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-sign-language"></i> fas
            fa-sign-language
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-sign-out-alt"></i> fas
            fa-sign-out-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-signal"></i> fas fa-signal
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-sitemap"></i> fas fa-sitemap
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-sliders-h"></i> fas fa-sliders-h
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-smile"></i> fas fa-smile
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-smoking"></i> fas fa-smoking
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-snowflake"></i> fas fa-snowflake
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-sort"></i> fas fa-sort
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-sort-alpha-down"></i> fas
            fa-alpha-down
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-sort-alpha-up"></i> fas
            fa-sort-alpha-up
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-sort-amount-down"></i> fas
            fa-sort-amount-down
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-sort-amount-up"></i> fas
            fa-sort-amount-up
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-sort-down"></i> fas fa-sort-down
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-sort-numeric-down"></i> fas
            fa-sort-numeric-down
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-sort-numeric-up"></i> fas
            fa-sort-numeric-up
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-sort-up"></i> fas fa-sort-up
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-space-shuttle"></i> fas
            fa-space-shuttle
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-spinner"></i> fas fa-spinner
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-square"></i> fas fa-square
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-square-full"></i> fas
            fa-square-full
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-star"></i> fas fa-star
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-star-half"></i> fas fa-star-half
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-step-backward"></i> fas
            fa-step-backward
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-step-forward"></i> fas
            fa-step-forward
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-stethoscope"></i> fas
            fa-stethoscope
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-sticky-note"></i> fas
            fa-sticky-note
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-stop"></i> fas fa-stop
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-stop-circle"></i> fas
            fa-stop-circle
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-stopwatch"></i> fas fa-stopwatch
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-street-view"></i> fas
            fa-street-view
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-strikethrough"></i> fas
            fa-strikethrough
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-subscript"></i> fas fa-subscript
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-subway"></i> fas fa-subway
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-suitcase"></i> fas fa-suitcase
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-sun"></i> fas fa-sun
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-superscript"></i> fas
            fa-superscript
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-sync"></i> fas fa-sync
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-sync-alt"></i> fas fa-sync-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-syringe"></i> fas fa-syringe
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-table"></i> fas fa-table
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-table-tennis"></i> fas
            fa-table-tennis
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-tablet"></i> fas fa-tablet
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-tablet-alt"></i> fas
            fa-tablet-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-tablets"></i> fas fa-tablets
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-tachometer-alt"></i> fas
            fa-tachometer-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-tag"></i> fas fa-tag
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-tags"></i> fas fa-tags
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-tape"></i> fas fa-tape
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-tasks"></i> fas fa-tasks
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-taxi"></i> fas fa-taxi
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-terminal"></i> fas fa-terminal
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-text-height"></i> fas
            fa-text-height
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-text-width"></i> fas
            fa-text-width
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-th"></i> fas fa-th
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-th-large"></i> fas fa-th-large
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-th-list"></i> fas fa-th-list
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-thermometer"></i> fas
            fa-thermometer
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-thermometer-empty"></i> fas
            fa-thermometer-empty
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-thermometer-full"></i> fas
            fa-thermometer-full
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-thermometer-half"></i> fas
            fa-thermometer-half
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-thermometer-quarter"></i> fas
            fa-thermometer-quarter
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-thermometer-three-quarters"></i>
            fas fa-thermometer-three-quarters
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-thumbs-down"></i> fas
            fa-thumbs-down
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-thumbs-up"></i> fas fa-thumbs-up
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-thumbtack"></i> fas fa-thumbtack
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-ticket-alt"></i> fas
            fa-ticket-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-times"></i> fas fa-times
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-times-circle"></i> fas
            fa-times-circle
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-tint"></i> fas fa-tint
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-toggle-off"></i> fas
            fa-toggle-off
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-toggle-on"></i> fas fa-toggle-on
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-trademark"></i> fas fa-trademark
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-train"></i> fas fa-train
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-transgender"></i> fas
            fa-transgender
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-transgender-alt"></i> fas
            fa-transgen
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-trash"></i> fas fa-trash
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-trash-alt"></i> fas fa-trash-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-tree"></i> fas fa-tree
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-trophy"></i> fas fa-trophy
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-truck"></i> fas fa-truck
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-truck-loading"></i> fas
            fa-truck-loading
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-truck-moving"></i> fas
            fa-truck-moving
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-tty"></i> fas fa-tty
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-tv"></i> fas fa-tv
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-umbrella"></i> fas fa-umbrella
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-underline"></i> fas fa-underline
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-undo"></i> fas fa-undo
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-undo-alt"></i> fas fa-undo-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-universal-access"></i> fas
            fa-universal-access
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-university"></i> fas
            fa-university
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-unlink"></i> fas fa-unlink
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-unlock"></i> fas fa-unlock
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-unlock-alt"></i> fas
            fa-unlock-alt
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-upload"></i> fas fa-upload
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-user"></i> fas fa-user
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-user-circle"></i> fas
            fa-user-circle
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-user-md"></i> fas fa-user-md
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-user-plus"></i> fas fa-user-plus
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-user-secret"></i> fas
            fa-user-secret
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-user-times"></i> fas
            fa-user-times
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-users"></i> fas fa-users
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-utensil-spoon"></i> fas
            fa-utensil-spoon
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-utensils"></i> fas fa-utensils
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-venus"></i> fas fa-venus
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-venus-double"></i> fas
            fa-venus-double
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-venus-mars"></i> fas
            fa-venus-mars
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-vial"></i> fas fa-vial
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-vials"></i> fas fa-vials
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-video"></i> fas fa-video
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-video-slash"></i> fas
            fa-video-slash
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-volleyball-ball"></i> fas
            fa-volleyba
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-volume-down"></i> fas
            fa-volume-down
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-volume-off"></i> fas
            fa-volume-off
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-volume-up"></i> fas fa-volume-up
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-warehouse"></i> fas fa-warehouse
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-weight"></i> fas fa-weight
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-wheelchair"></i> fas
            fa-wheelchair
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-wifi"></i> fas fa-wifi
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-window-close"></i> fas
            fa-window-close
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-window-maximize"></i> fas
            fa-window-maximize
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-window-minimize"></i> fas
            fa-window-minimize
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-window-restore"></i> fas
            fa-window-restore
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-wine-glass"></i> fas
            fa-wine-glass
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-won-sign"></i> fas fa-won-sign
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-wrench"></i> fas fa-wrench
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-x-ray"></i> fas fa-x-ray
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 text-truncate fas fa-yen-sign"></i> fas fa-yen-sign
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
    <b-card class="mb-4">
      <b-card-text>
        <h5 class="card-title">Regular Icons</h5>
        <b-row class="icons">
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-address-book"></i>
            <span>far fa-address-book</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-address-card"></i>
            <span>far fa-address-card</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-arrow-alt-circle-down"></i>
            <span>far fa-arrow-alt-circle-down</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-arrow-alt-circle-left"></i>
            <span>far fa-arrow-alt-circle-left</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-arrow-alt-circle-right"></i>
            <span>far fa-arrow-alt-circle-right</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-arrow-alt-circle-up"></i>
            <span>far fa-arrow-alt-circle-up</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-bell"></i>
            <span>far fa-bell</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-bell-slash"></i>
            <span>far fa-bell-slash</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-bookmark"></i>
            <span>far fa-bookmark</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-building"></i>
            <span>far fa-building</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-calendar"></i>
            <span>far fa-calendar</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-calendar-alt"></i>
            <span>far fa-calendar-alt</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-calendar-check"></i>
            <span>far fa-calendar-check</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-calendar-minus"></i>
            <span>far fa-calendar-minus</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-calendar-plus"></i>
            <span>far fa-calendar-plus</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-calendar-times"></i>
            <span>far fa-calendar-times</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-caret-square-down"></i>
            <span>far fa-caret-square-down</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-caret-square-left"></i>
            <span>far fa-caret-square-left</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-caret-square-right"></i>
            <span>far fa-caret-square-right</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-caret-square-up"></i>
            <span>far fa-caret-square-up</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-chart-bar"></i>
            <span>far fa-chart-bar</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-check-circle"></i>
            <span>far fa-check-circle</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-check-square"></i>
            <span>far fa-check-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-circle"></i>
            <span>far fa-circle</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-clipboard"></i>
            <span>far fa-clipboard</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-clock"></i>
            <span>far fa-clock</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-clone"></i>
            <span>far fa-clone</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-closed-captioning"></i>
            <span>far fa-closed-captioning</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-comment"></i>
            <span>far fa-comment</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-comment-alt"></i>
            <span>far fa-comment-alt</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-comments"></i>
            <span>far fa-comments</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-compass"></i>
            <span>far fa-compass</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-copy"></i>
            <span>far fa-copy</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-copyright"></i>
            <span>far fa-copyright</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-credit-card"></i>
            <span>far fa-credit-card</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-dot-circle"></i>
            <span>far fa-dot-circle</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-edit"></i>
            <span>far fa-edit</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-envelope"></i>
            <span>far fa-envelope</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-envelope-open"></i>
            <span>far fa-envelope-open</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-eye-slash"></i>
            <span>far fa-eye-slash</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-file"></i>
            <span>far fa-file</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-file-alt"></i>
            <span>far fa-file-alt</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-file-archive"></i>
            <span>far fa-file-archive</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-file-audio"></i>
            <span>far fa-file-audio</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-file-code"></i>
            <span>far fa-file-code</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-file-excel"></i>
            <span>far fa-file-excel</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-file-image"></i>
            <span>far fa-file-image</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-file-pdf"></i>
            <span>far fa-file-pdf</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-file-powerpoint"></i>
            <span>far fa-file-powerpoint</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-file-video"></i>
            <span>far fa-file-video</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-file-word"></i>
            <span>far fa-file-word</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-flag"></i>
            <span>far fa-flag</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-folder"></i>
            <span>far fa-folder</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-folder-open"></i>
            <span>far fa-folder-open</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-frown"></i>
            <span>far fa-frown</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-futbol"></i>
            <span>far fa-futbol</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-gem"></i>
            <span>far fa-gem</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-hand-lizard"></i>
            <span>far fa-hand-lizard</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-hand-paper"></i>
            <span>far fa-hand-paper</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-hand-peace"></i>
            <span>far fa-hand-peace</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-hand-point-down"></i>
            <span>far fa-hand-point-down</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-hand-point-left"></i>
            <span>far fa-hand-point-left</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-hand-point-right"></i>
            <span>far fa-hand-point-right</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-hand-point-up"></i>
            <span>far fa-hand-point-up</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-hand-pointer"></i>
            <span>far fa-hand-pointer</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-hand-rock"></i>
            <span>far fa-hand-rock</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-hand-scissors"></i>
            <span>far fa-hand-scissors</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-hand-spock"></i>
            <span>far fa-hand-spock</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-handshake"></i>
            <span>far fa-handshake</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-hdd"></i>
            <span>far fa-hdd</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-heart"></i>
            <span>far fa-heart</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-hospital"></i>
            <span>far fa-hospital</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-hourglass"></i>
            <span>far fa-hourglass</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-id-badge"></i>
            <span>far fa-id-badge</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-id-card"></i>
            <span>far fa-id-card</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-image"></i>
            <span>far fa-image</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-images"></i>
            <span>far fa-images</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-keyboard"></i>
            <span>far fa-keyboard</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-lemon"></i>
            <span>far fa-lemon</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-life-ring"></i>
            <span>far fa-life-ring</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-lightbulb"></i>
            <span>far fa-lightbulb</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-list-alt"></i>
            <span>far fa-list-alt</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-map"></i>
            <span>far fa-map</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-meh"></i>
            <span>far fa-meh</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-minus-square"></i>
            <span>far fa-minus-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-money-bill-alt"></i>
            <span>far fa-money-bill-alt</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-moon"></i>
            <span>far fa-moon</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-newspaper"></i>
            <span>far fa-newspaper</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-object-group"></i>
            <span>far fa-object-group</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-object-ungroup"></i>
            <span>far fa-object-ungroup</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-paper-plane"></i>
            <span>far fa-paper-plane</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-pause-circle"></i>
            <span>far fa-pause-circle</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-play-circle"></i>
            <span>far fa-play-circle</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-plus-square"></i>
            <span>far fa-plus-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-question-circle"></i>
            <span>far fa-question-circle</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-registered"></i>
            <span>far fa-registered</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-save"></i>
            <span>far fa-save</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-share-square"></i>
            <span>far fa-share-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-smile"></i>
            <span>far fa-smile</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-snowflake"></i>
            <span>far fa-snowflake</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-square"></i>
            <span>far fa-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-star"></i>
            <span>far fa-star</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-star-half"></i>
            <span>far fa-star-half</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-sticky-note"></i>
            <span>far fa-sticky-note</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-stop-circle"></i>
            <span>far fa-stop-circle</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-sun"></i>
            <span>far fa-sun</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-thumbs-down"></i>
            <span>far fa-thumbs-down</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-thumbs-up"></i>
            <span>far fa-thumbs-up</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-times-circle"></i>
            <span>far fa-times-circle</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-trash-alt"></i>
            <span>far fa-trash-alt</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-user"></i>
            <span>far fa-user</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-user-circle"></i>
            <span>far fa-user-circle</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-window-close"></i>
            <span>far fa-window-close</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-window-maximize"></i>
            <span>far fa-window-maximize</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-window-minimize"></i>
            <span>far fa-window-minimize</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 far fa-window-restore"></i>
            <span>far fa-window-restore</span>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
    <b-card class="mb-4">
      <b-card-text>
        <h5 class="card-title">Brand Icons</h5>
        <b-row class="icons">
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-500px"></i>
            <span>fab fa-500px</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-accessible-icon"></i>
            <span>fab fa-accessible-icon</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-accusoft"></i>
            <span>fab fa-accusoft</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-adn"></i>
            <span>fab fa-adn</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-adversal"></i>
            <span>fab fa-adversal</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-affiliatetheme"></i>
            <span>fab fa-affiliatetheme</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-algolia"></i>
            <span>fab fa-algolia</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-amazon"></i>
            <span>fab fa-amazon</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-amazon-pay"></i>
            <span>fab fa-amazon-pay</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-amilia"></i>
            <span>fab fa-amilia</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-android"></i>
            <span>fab fa-android</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-angellist"></i>
            <span>fab fa-angellist</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-angrycreative"></i>
            <span>fab fa-angrycreative</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-angular"></i>
            <span>fab fa-angular</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-app-store"></i>
            <span>fab fa-app-store</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-app-store-ios"></i>
            <span>fab fa-app-store-ios</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-apper"></i>
            <span>fab fa-apper</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-apple"></i>
            <span>fab fa-apple</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-apple-pay"></i>
            <span>fab fa-apple-pay</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-asymmetrik"></i>
            <span>fab fa-asymmetrik</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-audible"></i>
            <span>fab fa-audible</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-autoprefixer"></i>
            <span>fab fa-autoprefixer</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-avianex"></i>
            <span>fab fa-avianex</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-aviato"></i>
            <span>fab fa-aviato</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-aws"></i>
            <span>fab fa-aws</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-bandcamp"></i>
            <span>fab fa-bandcamp</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-behance"></i>
            <span>fab fa-behance</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-behance-square"></i>
            <span>fab fa-behance-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-bimobject"></i>
            <span>fab fa-bimobject</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-bitbucket"></i>
            <span>fab fa-bitbucket</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-bitcoin"></i>
            <span>fab fa-bitcoin</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-bity"></i>
            <span>fab fa-bity</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-black-tie"></i>
            <span>fab fa-black-tie</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-blackberry"></i>
            <span>fab fa-blackberry</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-blogger"></i>
            <span>fab fa-blogger</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-blogger-b"></i>
            <span>fab fa-blogger-b</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-bluetooth"></i>
            <span>fab fa-bluetooth</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-bluetooth-b"></i>
            <span>fab fa-bluetooth-b</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-btc"></i>
            <span>fab fa-btc</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-buromobelexperte"></i>
            <span>fab fa-buromobelexperte</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-cc-amazon-pay"></i>
            <span>fab fa-cc-amazon-pay</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-cc-amex"></i>
            <span>fab fa-cc-amex</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-cc-apple-pay"></i>
            <span>fab fa-cc-apple-pay</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-cc-diners-club"></i>
            <span>fab fa-cc-diners-club</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-cc-discover"></i>
            <span>fab fa-cc-discover</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-cc-jcb"></i>
            <span>fab fa-cc-jcb</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-cc-mastercard"></i>
            <span>fab fa-cc-mastercard</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-cc-paypal"></i>
            <span>fab fa-cc-paypal</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-cc-stripe"></i>
            <span>fab fa-cc-stripe</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-cc-visa"></i>
            <span>fab fa-cc-visa</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-centercode"></i>
            <span>fab fa-centercode</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-chrome"></i>
            <span>fab fa-chrome</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-cloudscale"></i>
            <span>fab fa-cloudscale</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-cloudsmith"></i>
            <span>fab fa-cloudsmith</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-cloudversify"></i>
            <span>fab fa-cloudversify</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-codepen"></i>
            <span>fab fa-codepen</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-codiepie"></i>
            <span>fab fa-codiepie</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-connectdevelop"></i>
            <span>fab fa-connectdevelop</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-contao"></i>
            <span>fab fa-contao</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-cpanel"></i>
            <span>fab fa-cpanel</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-creative-commons"></i>
            <span>fab fa-creative-commons</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-css3"></i>
            <span>fab fa-css3</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-css3-alt"></i>
            <span>fab fa-css3-alt</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-cuttlefish"></i>
            <span>fab fa-cuttlefish</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-d-and-d"></i>
            <span>fab fa-d-and-d</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-dashcube"></i>
            <span>fab fa-dashcube</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-delicious"></i>
            <span>fab fa-delicious</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-deploydog"></i>
            <span>fab fa-deploydog</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-deskpro"></i>
            <span>fab fa-deskpro</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-deviantart"></i>
            <span>fab fa-deviantart</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-digg"></i>
            <span>fab fa-digg</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-digital-ocean"></i>
            <span>fab fa-digital-ocean</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-discord"></i>
            <span>fab fa-discord</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-discourse"></i>
            <span>fab fa-discourse</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-dochub"></i>
            <span>fab fa-dochub</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-docker"></i>
            <span>fab fa-docker</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-draft2digital"></i>
            <span>fab fa-draft2digital</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-dribbble"></i>
            <span>fab fa-dribbble</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-dribbble-square"></i>
            <span>fab fa-dribbble-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-dropbox"></i>
            <span>fab fa-dropbox</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-drupal"></i>
            <span>fab fa-drupal</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-dyalog"></i>
            <span>fab fa-dyalog</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-earlybirds"></i>
            <span>fab fa-earlybirds</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-edge"></i>
            <span>fab fa-edge</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-elementor"></i>
            <span>fab fa-elementor</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-ember"></i>
            <span>fab fa-ember</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-empire"></i>
            <span>fab fa-empire</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-envira"></i>
            <span>fab fa-envira</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-erlang"></i>
            <span>fab fa-erlang</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-ethereum"></i>
            <span>fab fa-ethereum</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-etsy"></i>
            <span>fab fa-etsy</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-expeditedssl"></i>
            <span>fab fa-expeditedssl</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-facebook"></i>
            <span>fab fa-facebook</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-facebook-f"></i>
            <span>fab fa-facebook-f</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-facebook-messenger"></i>
            <span>fab fa-facebook-messenger</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-facebook-square"></i>
            <span>fab fa-facebook-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-firefox"></i>
            <span>fab fa-firefox</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-first-order"></i>
            <span>fab fa-first-order</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-firstdraft"></i>
            <span>fab fa-firstdraft</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-flickr"></i>
            <span>fab fa-flickr</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-flipboard"></i>
            <span>fab fa-flipboard</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-fly"></i>
            <span>fab fa-fly</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-font-awesome"></i>
            <span>fab fa-font-awesome</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-font-awesome-alt"></i>
            <span>fab fa-font-awesome-alt</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-font-awesome-flag"></i>
            <span>fab fa-font-awesome-flag</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-fonticons"></i>
            <span>fab fa-fonticons</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-fonticons-fi"></i>
            <span>fab fa-fonticons-fi</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-fort-awesome"></i>
            <span>fab fa-fort-awesome</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-fort-awesome-alt"></i>
            <span>fab fa-fort-awesome-alt</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-forumbee"></i>
            <span>fab fa-forumbee</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-foursquare"></i>
            <span>fab fa-foursquare</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-free-code-camp"></i>
            <span>fab fa-free-code-camp</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-freebsd"></i>
            <span>fab fa-freebsd</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-get-pocket"></i>
            <span>fab fa-get-pocket</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-gg"></i>
            <span>fab fa-gg</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-gg-circle"></i>
            <span>fab fa-gg-circle</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-git"></i>
            <span>fab fa-git</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-git-square"></i>
            <span>fab fa-git-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-github"></i>
            <span>fab fa-github</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-github-alt"></i>
            <span>fab fa-github-alt</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-github-square"></i>
            <span>fab fa-github-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-gitkraken"></i>
            <span>fab fa-gitkraken</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-gitlab"></i>
            <span>fab fa-gitlab</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-gitter"></i>
            <span>fab fa-gitter</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-glide"></i>
            <span>fab fa-glide</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-glide-g"></i>
            <span>fab fa-glide-g</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-gofore"></i>
            <span>fab fa-gofore</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-goodreads"></i>
            <span>fab fa-goodreads</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-goodreads-g"></i>
            <span>fab fa-goodreads-g</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-google"></i>
            <span>fab fa-google</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-google-drive"></i>
            <span>fab fa-google-drive</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-google-play"></i>
            <span>fab fa-google-play</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-google-plus"></i>
            <span>fab fa-google-plus</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-google-plus-g"></i>
            <span>fab fa-google-plus-g</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-google-plus-square"></i>
            <span>fab fa-google-plus-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-google-wallet"></i>
            <span>fab fa-google-wallet</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-gratipay"></i>
            <span>fab fa-gratipay</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-grav"></i>
            <span>fab fa-grav</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-gripfire"></i>
            <span>fab fa-gripfire</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-grunt"></i>
            <span>fab fa-grunt</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-gulp"></i>
            <span>fab fa-gulp</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-hacker-news"></i>
            <span>fab fa-hacker-news</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-hacker-news-square"></i>
            <span>fab fa-hacker-news-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-hips"></i>
            <span>fab fa-hips</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-hire-a-helper"></i>
            <span>fab fa-hire-a-helper</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-hooli"></i>
            <span>fab fa-hooli</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-hotjar"></i>
            <span>fab fa-hotjar</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-houzz"></i>
            <span>fab fa-houzz</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-html5"></i>
            <span>fab fa-html5</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-hubspot"></i>
            <span>fab fa-hubspot</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-imdb"></i>
            <span>fab fa-imdb</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-instagram"></i>
            <span>fab fa-instagram</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-internet-explorer"></i>
            <span>fab fa-internet-explorer</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-ioxhost"></i>
            <span>fab fa-ioxhost</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-itunes"></i>
            <span>fab fa-itunes</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-itunes-note"></i>
            <span>fab fa-itunes-note</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-jenkins"></i>
            <span>fab fa-jenkins</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-joget"></i>
            <span>fab fa-joget</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-joomla"></i>
            <span>fab fa-joomla</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-js"></i>
            <span>fab fa-js</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-js-square"></i>
            <span>fab fa-js-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-jsfiddle"></i>
            <span>fab fa-jsfiddle</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-keycdn"></i>
            <span>fab fa-keycdn</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-kickstarter"></i>
            <span>fab fa-kickstarter</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-kickstarter-k"></i>
            <span>fab fa-kickstarter-k</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-korvue"></i>
            <span>fab fa-korvue</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-laravel"></i>
            <span>fab fa-laravel</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-lastfm"></i>
            <span>fab fa-lastfm</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-lastfm-square"></i>
            <span>fab fa-lastfm-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-leanpub"></i>
            <span>fab fa-leanpub</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-less"></i>
            <span>fab fa-less</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-line"></i>
            <span>fab fa-line</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-linkedin"></i>
            <span>fab fa-linkedin</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-linkedin-in"></i>
            <span>fab fa-linkedin-in</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-linode"></i>
            <span>fab fa-linode</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-linux"></i>
            <span>fab fa-linux</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-lyft"></i>
            <span>fab fa-lyft</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-magento"></i>
            <span>fab fa-magento</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-maxcdn"></i>
            <span>fab fa-maxcdn</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-medapps"></i>
            <span>fab fa-medapps</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-medium"></i>
            <span>fab fa-medium</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-medium-m"></i>
            <span>fab fa-medium-m</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-medrt"></i>
            <span>fab fa-medrt</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-meetup"></i>
            <span>fab fa-meetup</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-microsoft"></i>
            <span>fab fa-microsoft</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-mix"></i>
            <span>fab fa-mix</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-mixcloud"></i>
            <span>fab fa-mixcloud</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-mizuni"></i>
            <span>fab fa-mizuni</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-modx"></i>
            <span>fab fa-modx</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-monero"></i>
            <span>fab fa-monero</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-napster"></i>
            <span>fab fa-napster</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-nintendo-switch"></i>
            <span>fab fa-nintendo-switch</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-node"></i>
            <span>fab fa-node</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-node-js"></i>
            <span>fab fa-node-js</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-npm"></i>
            <span>fab fa-npm</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-ns8"></i>
            <span>fab fa-ns8</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-nutritionix"></i>
            <span>fab fa-nutritionix</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-odnoklassniki"></i>
            <span>fab fa-odnoklassniki</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-odnoklassniki-square"></i>
            <span>fab fa-odnoklassniki-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-opencart"></i>
            <span>fab fa-opencart</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-openid"></i>
            <span>fab fa-openid</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-opera"></i>
            <span>fab fa-opera</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-optin-monster"></i>
            <span>fab fa-optin-monster</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-osi"></i>
            <span>fab fa-osi</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-page4"></i>
            <span>fab fa-page4</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-pagelines"></i>
            <span>fab fa-pagelines</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-palfed"></i>
            <span>fab fa-palfed</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-patreon"></i>
            <span>fab fa-patreon</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-paypal"></i>
            <span>fab fa-paypal</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-periscope"></i>
            <span>fab fa-periscope</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-phabricator"></i>
            <span>fab fa-phabricator</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-phoenix-framework"></i>
            <span>fab fa-phoenix-framework</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-php"></i>
            <span>fab fa-php</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-pied-piper"></i>
            <span>fab fa-pied-piper</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-pied-piper-alt"></i>
            <span>fab fa-pied-piper-alt</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-pied-piper-pp"></i>
            <span>fab fa-pied-piper-pp</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-pinterest"></i>
            <span>fab fa-pinterest</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-pinterest-p"></i>
            <span>fab fa-pinterest-p</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-pinterest-square"></i>
            <span>fab fa-pinterest-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-playstation"></i>
            <span>fab fa-playstation</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-product-hunt"></i>
            <span>fab fa-product-hunt</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-pushed"></i>
            <span>fab fa-pushed</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-python"></i>
            <span>fab fa-python</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-qq"></i>
            <span>fab fa-qq</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-quinscape"></i>
            <span>fab fa-quinscape</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-quora"></i>
            <span>fab fa-quora</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-ravelry"></i>
            <span>fab fa-ravelry</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-react"></i>
            <span>fab fa-react</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-readme"></i>
            <span>fab fa-readme</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-rebel"></i>
            <span>fab fa-rebel</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-red-river"></i>
            <span>fab fa-red-river</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-reddit"></i>
            <span>fab fa-reddit</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-reddit-alien"></i>
            <span>fab fa-reddit-alien</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-reddit-square"></i>
            <span>fab fa-reddit-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-rendact"></i>
            <span>fab fa-rendact</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-renren"></i>
            <span>fab fa-renren</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-replyd"></i>
            <span>fab fa-replyd</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-resolving"></i>
            <span>fab fa-resolving</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-rocketchat"></i>
            <span>fab fa-rocketchat</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-rockrms"></i>
            <span>fab fa-rockrms</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-safari"></i>
            <span>fab fa-safari</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-sass"></i>
            <span>fab fa-sass</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-schlix"></i>
            <span>fab fa-schlix</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-scribd"></i>
            <span>fab fa-scribd</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-searchengin"></i>
            <span>fab fa-searchengin</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-sellcast"></i>
            <span>fab fa-sellcast</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-sellsy"></i>
            <span>fab fa-sellsy</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-servicestack"></i>
            <span>fab fa-servicestack</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-shirtsinbulk"></i>
            <span>fab fa-shirtsinbulk</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-simplybuilt"></i>
            <span>fab fa-simplybuilt</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-sistrix"></i>
            <span>fab fa-sistrix</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-skyatlas"></i>
            <span>fab fa-skyatlas</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-skype"></i>
            <span>fab fa-skype</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-slack"></i>
            <span>fab fa-slack</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-slack-hash"></i>
            <span>fab fa-slack-hash</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-slideshare"></i>
            <span>fab fa-slideshare</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-snapchat"></i>
            <span>fab fa-snapchat</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-snapchat-ghost"></i>
            <span>fab fa-snapchat-ghost</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-snapchat-square"></i>
            <span>fab fa-snapchat-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-soundcloud"></i>
            <span>fab fa-soundcloud</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-speakap"></i>
            <span>fab fa-speakap</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-spotify"></i>
            <span>fab fa-spotify</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-stack-exchange"></i>
            <span>fab fa-stack-exchange</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-stack-overflow"></i>
            <span>fab fa-stack-overflow</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-staylinked"></i>
            <span>fab fa-staylinked</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-steam"></i>
            <span>fab fa-steam</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-steam-square"></i>
            <span>fab fa-steam-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-steam-symbol"></i>
            <span>fab fa-steam-symbol</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-sticker-mule"></i>
            <span>fab fa-sticker-mule</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-strava"></i>
            <span>fab fa-strava</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-stripe"></i>
            <span>fab fa-stripe</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-stripe-s"></i>
            <span>fab fa-stripe-s</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-studiovinari"></i>
            <span>fab fa-studiovinari</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-stumbleupon"></i>
            <span>fab fa-stumbleupon</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-stumbleupon-circle"></i>
            <span>fab fa-stumbleupon-circle</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-superpowers"></i>
            <span>fab fa-superpowers</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-supple"></i>
            <span>fab fa-supple</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-telegram"></i>
            <span>fab fa-telegram</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-telegram-plane"></i>
            <span>fab fa-telegram-plane</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-tencent-weibo"></i>
            <span>fab fa-tencent-weibo</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-themeisle"></i>
            <span>fab fa-themeisle</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-trello"></i>
            <span>fab fa-trello</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-tripadvisor"></i>
            <span>fab fa-tripadvisor</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-tumblr"></i>
            <span>fab fa-tumblr</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-tumblr-square"></i>
            <span>fab fa-tumblr-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-twitch"></i>
            <span>fab fa-twitch</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-twitter"></i>
            <span>fab fa-twitter</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-twitter-square"></i>
            <span>fab fa-twitter-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-typo3"></i>
            <span>fab fa-typo3</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-uber"></i>
            <span>fab fa-uber</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-uikit"></i>
            <span>fab fa-uikit</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-uniregistry"></i>
            <span>fab fa-uniregistry</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-untappd"></i>
            <span>fab fa-untappd</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-usb"></i>
            <span>fab fa-usb</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-ussunnah"></i>
            <span>fab fa-ussunnah</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-vaadin"></i>
            <span>fab fa-vaadin</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-viacoin"></i>
            <span>fab fa-viacoin</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-viadeo"></i>
            <span>fab fa-viadeo</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-viber"></i>
            <span>fab fa-viber</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-vimeo"></i>
            <span>fab fa-vimeo</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-vimeo-square"></i>
            <span>fab fa-vimeo-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-vimeo-v"></i>
            <span>fab fa-vimeo-v</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-vine"></i>
            <span>fab fa-vine</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-vk"></i>
            <span>fab fa-vk</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-vnv"></i>
            <span>fab fa-vnv</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-vuejs"></i>
            <span>fab fa-vuejs</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-weibo"></i>
            <span>fab fa-weibo</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-weixin"></i>
            <span>fab fa-weixin</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-whatsapp"></i>
            <span>fab fa-whatsapp</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-whatsapp-square"></i>
            <span>fab fa-whatsapp-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-whmcs"></i>
            <span>fab fa-whmcs</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-wikipedia-w"></i>
            <span>fab fa-wikipedia-w</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-windows"></i>
            <span>fab fa-windows</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-wordpress"></i>
            <span>fab fa-wordpress</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-wordpress-simple"></i>
            <span>fab fa-wordpress-simple</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-wpbeginner"></i>
            <span>fab fa-wpbeginner</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-wpexplorer"></i>
            <span>fab fa-wpexplorer</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-wpforms"></i>
            <span>fab fa-wpforms</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-xbox"></i>
            <span>fab fa-xbox</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-xing"></i>
            <span>fab fa-xing</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-xing-square"></i>
            <span>fab fa-xing-square</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-y-combinator"></i>
            <span>fab fa-y-combinator</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-yahoo"></i>
            <span>fab fa-yahoo</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-yandex"></i>
            <span>fab fa-yandex</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-yandex-international"></i>
            <span>fab fa-yandex-international</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-yelp"></i>
            <span>fab fa-yelp</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-yoast"></i>
            <span>fab fa-yoast</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-youtube"></i>
            <span>fab fa-youtube</span>
          </b-col>
          <b-col cols="12" lg="3" md="4" class="my-1 d-flex align-items-center">
            <i class="mr-2 fab fa-youtube-square"></i>
            <span>fab fa-youtube-square</span>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "FontAwesomeIcons",
  data: () => ({
    page: {
      title: "Font Awesome Icons",
    },
  }),
};
</script>